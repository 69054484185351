import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectModule } from 'ng2-select';
import { ClientholidayComponent } from './clientholiday.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BoxModule } from 'angular-admin-lte';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TimepickerModule, BsDatepickerModule } from 'ngx-bootstrap';
import { DynamicFormModule } from 'src/app/dynamic-form/dynamic-form.module';
import { CommonUtilModule } from '../../../services/common.module';
import { GridAllModule, PagerModule } from '@syncfusion/ej2-angular-grids';
import { UploaderModule } from '@syncfusion/ej2-angular-inputs';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    NgbModule,
    BoxModule, SelectModule,
    GridAllModule,
    PagerModule,
    UploaderModule,
    DynamicFormModule, ReactiveFormsModule,
    DropDownListModule,
    TimepickerModule.forRoot(),
    BsDatepickerModule.forRoot(),CommonUtilModule,
   
  ],
  declarations: [ClientholidayComponent],
  exports:[ClientholidayComponent]
})
export class ClientholidayModule {

}
