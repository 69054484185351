import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { CommonModule } from "@angular/common";

import { HometestRoutingModule } from "./ProjectHealth-routing.module";
import { ProjectHealthComponent } from "./ProjectHealth.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BsDatepickerModule } from "ngx-bootstrap"; 
import { ChartComponent } from './charts/chart.component';

import {  ColumnComponent } from "./charts/columnchart.component";
import { HighchartsChartModule } from "highcharts-angular";
import { GaugeComponent } from "./charts/gaugechart.component";
import more from 'highcharts/highcharts-more.src';
import solidGauge from 'highcharts/modules/solid-gauge.src';
import { ChartModule, HIGHCHARTS_MODULES } from "angular-highcharts";
import { CircularGaugeModule } from '@syncfusion/ej2-angular-circulargauge';
import { AccumulationChartModule, BarSeriesService, CategoryService, ChartAllModule, StackingBarSeriesService } from '@syncfusion/ej2-angular-charts';
import { GradientService } from '@syncfusion/ej2-angular-circulargauge';
import { PieSeriesService, AccumulationLegendService, AccumulationTooltipService, AccumulationAnnotationService,
  AccumulationDataLabelService } from '@syncfusion/ej2-angular-charts';
  import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';
export function highchartsModules() {
  // apply Highcharts Modules to this array
  return [more, solidGauge];
}

@NgModule({
  imports: [
    CommonModule,
    HometestRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BsDatepickerModule,HighchartsChartModule,
    ChartModule,
    CircularGaugeModule,
    AccumulationChartModule,
    ChartAllModule,DatePickerModule
  ],
  declarations: [ProjectHealthComponent,ChartComponent,ColumnComponent,GaugeComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  providers: [
    { provide: HIGHCHARTS_MODULES, useFactory: highchartsModules }, // add as factory to your providers
    GradientService, PieSeriesService, AccumulationTooltipService, 
    BarSeriesService, StackingBarSeriesService, CategoryService
  ],
})
export class ProjectHealthModule {}
