import { Injectable } from '@angular/core';

@Injectable()
export class OrgStructure {
    orgStructureId: number;
    orgSOrder:number
    name: string;
    description: string;
    inActive: boolean = true;
    //createdBy: string;
    updatedBy: string;
    notes:string;
    attributes: any;
}

export class Listing {
    pageNo: number = 1;
    noOfRecords: number = 10;
    sortType: string = 'asc';
    fieldName: string = 'Name';
    condition: string;
    CmpCode:number;
}

export class OrgStructureLevelElements {
    orgStructureId: number;
    orgStructureLevelId: number;
    name: string;
    orgStructureName: string;
    orgSOrder:number
    description: string;
    inActive: boolean = true;
    //createdBy: string;
    updatedBy: string;
    notes:string;
    attributes: any;
}